import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiLogIn, FiMail, FiLock, FiArrowLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';

// import { Container, Content, AnimationContainer, Background } from './styles';
import { AnimationContainer, Or } from './styles';

import api from '../../services/api';

interface SignInFormData {
  email: string;
  password: string;
}

interface ProtocolFormData {
  protocol: string;
}

interface Answers {
  situation: number;
  date: string;
  answer: string;
}

interface Protocol {
  protocol: string;
  type: string;
  category: string;
  description: string;
  situation: number;
  answers: Answers[];
}

interface Sponsors {
  id: string;
  name: string;
  image: string;
  url: string;
  // view: string;
}

interface Widget {
  anonymous_widget: number;
  sponsors: Sponsors[];
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [showProtocol, setShowProtocol] = useState(false);
  const [getProtocol, setProtocol] = useState<Protocol[]>([]);
  // const [answers, setAnswers] = useState<Answers[]>([]);
  const [noAnswer, setNoAnswer] = useState('');
  const [anonymousWidget, setAnonymousWidget] = useState(0);

  const [getSponsors, setSponsors] = useState<Sponsors[]>([]);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    const key = localStorage.getItem('@FalaCidadao:key');

    if (key) {
      api
        .get<Widget>(`/client/${key}`, {
          params: {},
        })
        .then(response => {
          setAnonymousWidget(response.data.anonymous_widget);
          setSponsors(response.data.sponsors);
        });
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigtória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
        });
      }
    },
    [signIn, addToast, history],
  );

  const handleProtocolSubmit = useCallback(
    async (data: ProtocolFormData) => {
      setProtocol([]);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          protocol: Yup.string().required('Protocolo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .get<Protocol>(`/occurrences/protocol/${data.protocol}`, {
            params: {},
          })
          .then(response => {
            // console.log(response.data);
            const getAnswers = response.data?.answers;
            setProtocol([
              ...getProtocol,
              {
                protocol: response.data.protocol,
                type: response.data.type,
                category: response.data.category,
                description: response.data.description,
                situation: response.data.situation,
                answers: getAnswers,
              },
            ]);
            // setAnswers(getAnswers);
            setShowProtocol(true);

            if (getAnswers.length === 0) setNoAnswer('Nenhuma resposta.');
            else setNoAnswer('');
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao consultar protocolo',
          description:
            'Ocorreu um erro ao consultar o protocolo, tente novamente.',
        });
      }
    },
    [addToast, getProtocol],
  );

  return (
    <Container>
      <Row>
        <Col style={{ textAlign: 'center', margin: 40 }}>
          <img src={logoImg} alt="" />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <h1>Painel administrativo de ocorrências</h1>
          <p>
            Para acompanhar uma solicitação de ocorrência registrada você deve
            ser o solicitante da ocorrência.
          </p>
        </Col>
      </Row>

      {showProtocol && (
        <Row style={{ marginTop: 80, marginBottom: 80 }}>
          <Col>
            <AnimationContainer>
              <Accordion
                defaultActiveKey={getProtocol[0].protocol}
                style={{ width: '80%' }}
              >
                <Card>
                  <Card.Header>
                    <div
                      style={{
                        float: 'left',
                        width: '45%',
                      }}
                    >
                      <Accordion.Toggle eventKey={getProtocol[0].protocol}>
                        {getProtocol[0].protocol}
                      </Accordion.Toggle>
                      <div>
                        <span>{getProtocol[0].type}</span>
                        <span> - </span>
                        <span>{getProtocol[0].category}</span>
                      </div>
                    </div>
                    <div
                      style={{
                        float: 'right',
                        width: '45%',
                        textAlign: 'right',
                        color: '#00aae6',
                        paddingTop: 16,
                      }}
                    >
                      {getProtocol[0].situation === 1 && <span>Recebido</span>}
                      {getProtocol[0].situation === 2 && (
                        <span>Em andamento</span>
                      )}
                      {getProtocol[0].situation === 3 && <span>Resolvido</span>}
                      {getProtocol[0].situation === 4 && (
                        <span style={{ color: 'red' }}>Negado</span>
                      )}
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey={getProtocol[0].protocol}>
                    <Card.Body>
                      <div
                        style={{
                          width: '50%',
                          float: 'left',
                          paddingBottom: 20,
                          paddingRight: 40,
                          borderRight: '1px solid #f1f1f1',
                        }}
                      >
                        {getProtocol[0].description}
                      </div>
                      <div
                        style={{
                          width: '50%',
                          float: 'right',
                          paddingLeft: 40,
                          paddingBottom: 20,
                        }}
                      >
                        <div
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                          }}
                        >
                          Situação da Ocorrência
                        </div>
                        {getProtocol[0].answers.length === 0 && (
                          <div>{noAnswer}</div>
                        )}
                        {getProtocol[0].answers.map(answer => (
                          <div style={{ marginTop: 10 }}>
                            <div
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 14,
                                fontWeight: 400,
                              }}
                            >
                              {answer.situation === 1 && <span>Recebido</span>}
                              {answer.situation === 2 && (
                                <span>Em andamento</span>
                              )}
                              {answer.situation === 3 && <span>Resolvido</span>}
                              {answer.situation === 4 && (
                                <span style={{ color: 'red' }}>Negado</span>
                              )}
                            </div>
                            <div style={{ fontSize: 13, color: '#00aae6' }}>
                              {answer.date}
                            </div>
                            <div style={{ fontSize: 15 }}>{answer.answer}</div>
                          </div>
                        ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Link
                to="/"
                onClick={() => {
                  setShowProtocol(false);
                }}
              >
                <FiArrowLeft />
                Voltar
              </Link>
            </AnimationContainer>
          </Col>
        </Row>
      )}

      {!showProtocol && (
        <Row style={{ marginTop: 80, marginBottom: 80 }}>
          <Col>
            <AnimationContainer>
              <h2>Login Fala Cidadão</h2>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input name="email" icon={FiMail} placeholder="E-mail" />
                <Input
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Senha"
                />
                <Link to="/forgot-password">Esqueci minha senha</Link>
                <Button type="submit">Entrar</Button>
              </Form>
              <Or>ou</Or>
              <Link to="/signup">
                <FiLogIn />
                Criar uma conta
              </Link>
            </AnimationContainer>
          </Col>
          {anonymousWidget === 1 && (
            <Col>
              <AnimationContainer>
                <h2>Não Identificado </h2>
                <p>
                  As ocorrências como “Não Identificado” são consideradas
                  “Comunicações”, com acompanhamento apenas pelo protocolo.
                </p>
                <p>
                  Caso deseje acompanhar o andamento da sua ocorrência e receber
                  uma resposta, por favor identifique-se.
                </p>
                <Button
                  type="submit"
                  onClick={() => {
                    history.push('/anonymous');
                  }}
                >
                  Continuar sem me identificar
                </Button>
              </AnimationContainer>
            </Col>
          )}
          <Col>
            <AnimationContainer>
              <h2>Consulta de Protocolo</h2>
              <Form ref={formRef} onSubmit={handleProtocolSubmit}>
                <p>
                  Informe o protocolo da sua ocorrência para obter informações
                  sobre o andamento da sua ocorrência.
                </p>
                <Input name="protocol" placeholder="Informe o protocolo" />
                <Button type="submit">Consultar</Button>
              </Form>
            </AnimationContainer>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: 40, marginBottom: 20 }}>
        <Col>
          <div>
            <div style={{ fontSize: 12, textAlign: 'center' }}>
              Patrocinado por
            </div>
            <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
              {getSponsors.map(sponsor => (
                <li
                  style={{
                    width: 200,
                    display: 'inline-block',
                    listStyle: 'none',
                  }}
                  key={sponsor.id}
                >
                  <a href={`${sponsor.url}&source=widget`} target="noreferrer">
                    <img
                      src={`${sponsor.image}&source=widget`}
                      alt={sponsor.name}
                      title={sponsor.name}
                      style={{ width: '100%' }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
