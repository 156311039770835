import React, {
  FC,
  SelectHTMLAttributes,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  options: Array<{
    id: string;
    name: string;
  }>;
}

const Select: FC<ISelectProps> = ({
  hidden,
  label,
  name,
  options,
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, error, registerField } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isHidden, setHidden] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setHidden(hidden || false);
  }, [hidden]);

  return (
    <Container
      hidden={isHidden}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <select
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue=""
        id={name}
        ref={selectRef}
        {...rest}
      >
        <option value="" disabled>
          {label}
        </option>

        {options.map(({ name: opLabel, id }) => (
          <option key={id} value={id}>
            {opLabel}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default Select;
