import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FiArrowLeft, FiUser, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Downshift from 'downshift';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  // Container,
  // Content,
  AnimationContainer,
  // Background,
} from './styles';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface City {
  id: string;
  name: string;
}

const SignUp: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [options, setOptions] = useState<City[]>([]);
  const [searchCity, setSearchCity] = useState('');
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    if (searchCity) {
      api
        .post<City[]>(`/search/city`, { search: searchCity })
        .then(response => {
          setOptions(response.data);
        });
    }
  }, [searchCity]);

  const handleSearchCity = useCallback(event => {
    setSearchCity(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(8, 'No mínimo 8 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!selectedOption) {
          addToast({
            type: 'error',
            title: 'Cidade!',
            description: 'Busque sua cidade.',
          });
          return;
        }

        await api.post('/register', {
          name: data.name,
          email: data.email,
          password: data.password,
          city_id: selectedOption,
          source: 4,
        });

        history.push('/');

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description:
            'Foi enviado um link por e-mail para confirmar seu cadastro.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        let descriptionError =
          'Ocorreu um erro ao fazer cadastro, tente novamente.';

        if (err.response) {
          if (err.response.data) {
            if (err.response.data.email) {
              descriptionError = err.response.data.email;
            }
          }
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro!',
          description: descriptionError,
        });
      }
    },
    [addToast, history, selectedOption],
  );

  return (
    <Container>
      <Row>
        <Col style={{ textAlign: 'center', margin: 40 }}>
          <img src={logoImg} alt="" />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <h1>Painel administrativo de ocorrências</h1>
          <p>
            Para acompanhar uma solicitação de ocorrência registrada você deve
            ser o solicitante da ocorrência.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 80, marginBottom: 80 }}>
        <Col />
        <Col>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit} autoComplete="nope">
              <h2>Faça seu cadastro</h2>
              <Input name="name" icon={FiUser} placeholder="Nome" />
              <Input name="email" icon={FiMail} placeholder="E-mail" />
              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Senha"
              />
              {selectedOption && (
                <div
                  style={{
                    border: '1px solid #dbdbdb',
                    textAlign: 'left',
                    padding: 16,
                  }}
                >
                  {selectedCity}
                  <div
                    style={{
                      position: 'absolute',
                      width: 320,
                      textAlign: 'right',
                      marginTop: -54,
                    }}
                  >
                    <Button
                      onClick={() => {
                        setSelectedOption('');
                        setSelectedCity('');
                      }}
                      style={{
                        cursor: 'pointer',
                        color: 'red',
                        fontSize: 12,
                        background: '#ffffff',
                        width: 80,
                      }}
                    >
                      alterar
                    </Button>
                  </div>
                </div>
              )}
              {!selectedOption && (
                <Downshift
                  onChange={event => {
                    setSelectedOption(event.id);
                    setSelectedCity(event.name);
                  }}
                  itemToString={item => (item ? item.name : '')}
                >
                  {({
                    selectedItem,
                    getInputProps,
                    getItemProps,
                    highlightedIndex,
                    isOpen,
                    inputValue,
                    // getLabelProps,
                  }) => (
                    <div>
                      <Input
                        name="search"
                        type="search"
                        placeholder="Buscar sua cidade"
                        {...getInputProps({
                          autoComplete: 'nope',
                          onChange: handleSearchCity,
                        })}
                      />
                      {isOpen ? (
                        <div
                          style={{
                            position: 'absolute',
                            width: '340px',
                            textAlign: 'left',
                          }}
                        >
                          {options
                            .filter(item => !inputValue || item.name)
                            .slice(0, 10)
                            .map((item, index) => (
                              <div
                                className="dropdown-item"
                                {...getItemProps({ key: index, index, item })}
                                style={{
                                  backgroundColor:
                                    highlightedIndex === index
                                      ? 'lightgray'
                                      : 'white',
                                  fontWeight:
                                    selectedItem === item ? 'bold' : 'normal',
                                }}
                              >
                                {item.name}
                              </div>
                            ))}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Downshift>
              )}
              <Button type="submit">Cadastrar</Button>
            </Form>
            <Link to="/">
              <FiArrowLeft />
              Voltar para login
            </Link>
          </AnimationContainer>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export default SignUp;
