import React, { useRef, useCallback, useEffect, useState } from 'react';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';

// import { FiClock, FiPower } from 'react-icons/fi';
// import { Link } from 'react-router-dom';

import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Select from '../../components/Select';
import Button from '../../components/Button';

/* import {
  Container,
  Header,
  HeaderContent,
  Profile,
  Content,
  Schedule,
  NextAppointment,
  Section,
  Appointment,
  Calendar,
} from './styles'; */

import logoImg from '../../assets/logo.svg';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

interface Occurrence {
  id: string;
  city: string;
  protocol: string;
  type: string;
  category: string;
  description: string;
  date: string;
  situation: number;
}

interface Type {
  id: string;
  name: string;
}

interface Client {
  name: string;
  image: string;
  city_id: string;
  occurrence: number;
  anonymous: number;
}

interface Option {
  id: string;
  name: string;
}

interface Answers {
  situation: number;
  date: string;
  answer: string;
}

interface Answer {
  id: string;
  name: string;
  image: string;
  answers: Answers[]; // Answers;
}

const Dashboard: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToast();
  const { signOut, user } = useAuth();
  const [occurrences, setOccurrences] = useState<Occurrence[]>([]);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [types, setTypes] = useState<Type[]>([]);
  const [client, setClient] = useState<Client>();
  const [subTypes, setSubtypes] = useState<Type[]>([]);
  const [typeId, setTypeId] = useState('');
  const [inputType, setInputType] = useState(true);
  const [inputPublic, setInputPublic] = useState(false);
  const [inputAnonymous, setInputAnonymous] = useState(false);
  const [address, setAddress] = useState('');
  const [lat, setlat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [disabledAddress, setDisabledAddress] = useState(false);
  const [cityId, setCityId] = useState('');
  const [viewOccurrence, setViewOccurrence] = useState('');
  const [noAnswer, setNoAnswer] = useState('');

  const [publicOptions, setPublicOptions] = useState<Option[]>([]);
  const [anonymousOptions, setAnonymousOptions] = useState<Option[]>([]);

  useEffect(() => {
    setPublicOptions([
      {
        id: '0',
        name: 'Visível apenas para mim',
      },
      {
        id: '1',
        name: 'Visível para todos',
      },
    ]);
    setAnonymousOptions([
      {
        id: '0',
        name: 'Anônimo: Não (Seus dados ficarão visíveis)',
      },
      {
        id: '1',
        name: 'Anônimo: Sim (Seus dados ficarão como anônimo)',
      },
    ]);
  }, []);

  /* useEffect(() => {
    formRef.current?.setData({ public: '0' });
    setInputPublic();
  }, []); */

  useEffect(() => {
    const key = localStorage.getItem('@FalaCidadao:key');

    api
      .get<Client>(`/client/${key}`, {
        params: {},
      })
      .then(response => {
        setClient(response.data);
        setCityId(response.data.city_id);

        if (response.data.occurrence === 1) {
          formRef.current?.setData({ public: '1' });
          setInputPublic(true);
        }
        if (response.data.occurrence === 2) {
          formRef.current?.setData({ public: '0' });
          setInputPublic(true);
        }
        if (response.data.occurrence === 3) {
          formRef.current?.setData({ public: '0' });
          setInputPublic(false);
        }

        if (response.data.anonymous === 1) {
          formRef.current?.setData({ anonymous: '0' });
          setInputAnonymous(false);
        }
        if (response.data.anonymous === 0) {
          formRef.current?.setData({ anonymous: '0' });
          setInputAnonymous(true);
        }
      });
  }, []);

  useEffect(() => {
    if (cityId) {
      api
        .get<Type[]>(`/types/${cityId}`, {
          params: {},
        })
        .then(response => {
          setTypes(response.data);
        });
    }
  }, [cityId]);

  useEffect(() => {
    setAnswers([]);
    if (viewOccurrence) {
      api
        .get<Answer>(`/answers/${viewOccurrence}`, {
          params: {},
        })
        .then(response => {
          const getAnswers = response.data.answers;
          setAnswers(getAnswers);

          if (getAnswers.length === 0) setNoAnswer('Nenhuma resposta.');
          else setNoAnswer('');
        });
    }
  }, [viewOccurrence]);

  useEffect(() => {
    /* setOccurrences([]); */
    api
      .get('/occurrences', {
        // get<Occurrence[]>
        params: {},
      })
      .then(response => {
        // console.log(response.data);
        const { data } = response;
        // console.log(data.items);
        setOccurrences(data.items);
        // setOccurrences([]);
        // alert('a');
      });
  }, []);

  interface OccurrenceFormData {
    type_id: string;
    city_id: string;
    description: string;
    address: string;
    latitude: string;
    longitude: string;
    public: number;
    anonymous: number;
    source: number;
  }

  useEffect(() => {
    if (typeId !== '') {
      api
        .get(`/subtypes/${typeId}`, {
          params: {},
        })
        .then(response => {
          setSubtypes(response.data);
        });
    }
  }, [typeId]);

  const handleSubmit = useCallback(
    async (data: OccurrenceFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Descrição obrigatória'),
          type: Yup.string().required('Selecione a categoria'),
          type_id: Yup.string().required('Selecione o tipo de ocorrência'),
          address: Yup.string().required('Informe o endereço da ocorrência'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // console.log(data);

        await api
          .post('occurrences', {
            type_id: data.type_id,
            city_id: cityId,
            description: data.description,
            address: data.address,
            latitude: lat,
            longitude: lng,
            public: Number(data.public),
            anonymous: Number(data.anonymous),
            source: 4,
          })
          .then(response => {
            if (containerRef.current) {
              window.scrollTo({
                behavior: 'smooth',
                top: containerRef.current.offsetTop,
              });
            }
            setOccurrences([
              {
                id: response.data.id,
                city: response.data.city,
                protocol: response.data.protocol,
                type: response.data.type,
                category: response.data.category,
                description: response.data.description,
                date: response.data.date,
                situation: response.data.situation,
              },
              ...occurrences,
            ]);
            // occurrences.map(i => console.log(i));
            // console.log(response.data);
            // setOccurrences(response.data);
          });

        formRef.current?.reset();
        setInputType(true);
        setDisabledAddress(false);
        setAddress('');
        setlat(0);
        setLng(0);

        addToast({
          type: 'success',
          title: 'Enviado com sucesso',
          description: 'Sua ocorrência foi enviada com sucesso.',
        });

        /* await signIn({
          email: data.email,
          password: data.password,
        }); */

        // history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao enviar',
          description:
            'Ocorreu um erro ao enviar a ocorrência, verifique se preencheu todos os dados.',
        });
      }
    },
    [addToast, occurrences, cityId, lat, lng],
  );

  return (
    <Container ref={containerRef}>
      <Row>
        <Col style={{ textAlign: 'center', margin: 40 }}>
          <img src={logoImg} alt="Fala Cidadão APP" />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <h1>Painel administrativo de ocorrências</h1>
          <p>
            Para acompanhar uma solicitação de ocorrência registrada você deve
            ser o solicitante da ocorrência.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 80, marginBottom: 80 }}>
        <Col>
          <Tab.Container id="left-tabs-example" defaultActiveKey="create">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="create">Criar Ocorrência</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="list">Minhas Ocorrências</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="logout" onClick={signOut}>
                      Sair
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <div
                  style={{
                    marginTop: 40,
                    fontSize: 11,
                    paddingLeft: 20,
                  }}
                >
                  <div>Você está logado como: </div>
                  <div>
                    <b>{user.name}</b>
                  </div>
                </div>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="create">
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <Textarea
                        name="description"
                        placeholder="Descreva o que está acontecendo"
                      />
                      <Select
                        onChange={e => {
                          // console.log(e.target.value);
                          setTypeId(e.target.value);
                          setInputType(false);
                          // selectType;
                        }}
                        name="type"
                        label="Categoria"
                        options={types}
                      />
                      <Select
                        hidden={inputType}
                        name="type_id"
                        label="Tipo de ocorrência"
                        options={subTypes}
                      />
                      <Select
                        hidden={inputPublic}
                        name="public"
                        label="Visibilidade da ocorrência"
                        options={publicOptions}
                      />
                      <Select
                        hidden={inputAnonymous}
                        name="anonymous"
                        label="Visibilidade dos meus dados"
                        options={anonymousOptions}
                      />
                      {/* <Input
                        name="address"
                        placeholder="Endereço da ocorrência"
                      /> */}
                      {/* <GooglePlacesAutocomplete
                        apiKey="AIzaSyDhOz6DfcsSqJ-ly05JqRK794DUPfl2kos"
                        selectProps={{
                          innerProps: {
                            id: 'address',
                          },
                          address,
                          onChange: setAddress,
                          placeholder: 'Endereço da ocorrência',
                        }}
                      /> */}

                      <PlacesAutocomplete
                        value={address}
                        onChange={e => {
                          // console.log(e);
                          setAddress(e);
                        }}
                        onSelect={e => {
                          // console.log(e);
                          // setAddress();
                          setAddress(e);

                          geocodeByAddress(address)
                            .then(results => getLatLng(results[0]))
                            .then(latLng => {
                              // console.log(latLng.lat);
                              setlat(latLng.lat);
                              setLng(latLng.lng);
                            });
                          /* .catch(error => {
                              if (error) alert(error);
                            }) */

                          setDisabledAddress(true);
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <div
                              style={{
                                background: '#ffffff',
                                borderRadius: 5,
                                padding: 16,
                                width: '100%',
                                border: '1px solid #dbdbdb',
                                color: '#666666',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              hidden={!disabledAddress}
                            >
                              {address}
                            </div>

                            {disabledAddress === false && (
                              <div>
                                <Input
                                  name="search_address"
                                  placeholder="Endereço da ocorrência"
                                  {...getInputProps({})}
                                />
                              </div>
                            )}

                            <div
                              style={{
                                visibility: 'hidden',
                                position: 'absolute',
                              }}
                            >
                              <Input
                                name="address"
                                placeholder="Endereço da ocorrência"
                                {...getInputProps({})}
                              />
                            </div>

                            {/* <input
                              name="address"
                              hidden={disabledAddress}
                              style={{
                                width: '100%',
                                flex: 1,
                                background: 'transparent',
                                border: 0,
                                color: '#000',
                              }}
                              {...getInputProps({
                                placeholder: 'Endereço da ocorrência',
                                className: 'location-search-input',
                              })}
                            /> */}
                            <div>
                              {loading && <div>Carregando...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={suggestion.description}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>

                      <Button type="submit">Enviar ocorrência</Button>
                      {client && (
                        <div
                          style={{
                            marginTop: 20,
                            fontSize: 12,
                          }}
                        >
                          <div>Você está enviando essa ocorrência para:</div>
                          <div>
                            <b>{client.name}</b>
                          </div>
                        </div>
                      )}
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="list">
                    {/* {occurrences.length > 0 && ( */}
                    <Accordion>
                      {occurrences.map(occurrence => (
                        <Card key={occurrence.id}>
                          <Card.Header>
                            <div
                              style={{
                                float: 'left',
                                width: '45%',
                              }}
                            >
                              <Accordion.Toggle
                                onClick={() => setViewOccurrence(occurrence.id)}
                                /* onClick={
                                    setViewOccurrence(occurrence.id)
                                  } */
                                eventKey={occurrence.id}
                              >
                                {occurrence.protocol}
                              </Accordion.Toggle>
                              <div>
                                <span>{occurrence.type}</span>
                                <span> - </span>
                                <span>{occurrence.category}</span>
                              </div>
                            </div>
                            <div
                              style={{
                                float: 'right',
                                width: '45%',
                                textAlign: 'right',
                                color: '#00aae6',
                                paddingTop: 16,
                              }}
                            >
                              {occurrence.situation === 1 && (
                                <span>Recebido</span>
                              )}
                              {occurrence.situation === 2 && (
                                <span>Em andamento</span>
                              )}
                              {occurrence.situation === 3 && (
                                <span>Resolvido</span>
                              )}
                              {occurrence.situation === 4 && (
                                <span style={{ color: 'red' }}>Negado</span>
                              )}
                            </div>
                          </Card.Header>
                          <Accordion.Collapse eventKey={occurrence.id}>
                            <Card.Body>
                              <div
                                style={{
                                  width: '50%',
                                  float: 'left',
                                  paddingBottom: 20,
                                  paddingRight: 40,
                                  borderRight: '1px solid #f1f1f1',
                                }}
                              >
                                {occurrence.description}
                              </div>
                              <div
                                style={{
                                  width: '50%',
                                  float: 'right',
                                  paddingLeft: 40,
                                  paddingBottom: 20,
                                }}
                              >
                                <div
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: 400,
                                  }}
                                >
                                  Situação da Ocorrência
                                </div>
                                {answers.length === 0 && <div>{noAnswer}</div>}
                                {answers.map(answer => (
                                  <div style={{ marginTop: 10 }}>
                                    <div
                                      style={{
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                        fontWeight: 400,
                                      }}
                                    >
                                      {answer.situation === 1 && (
                                        <span>Recebido</span>
                                      )}
                                      {answer.situation === 2 && (
                                        <span>Em andamento</span>
                                      )}
                                      {answer.situation === 3 && (
                                        <span>Resolvido</span>
                                      )}
                                      {answer.situation === 4 && (
                                        <span style={{ color: 'red' }}>
                                          Negado
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 13,
                                        color: '#00aae6',
                                      }}
                                    >
                                      {answer.date}
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                      {answer.answer}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ))}
                    </Accordion>
                    {/* )} */}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
