import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #fff;
    color: #000;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Roboto', serif;
    font-size: 18px;
    font-weight: 300;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 20px;
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 28px;
  }

  p {
    font-size: 16px;
  }

  button {
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0px 1000px #232129 inset;
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }

  .nav-link {
    color: #201EFF !important;
    height: 50px;
    line-height: 32px;
    border: 1px solid #ffffff;
  }
  .nav-link.active {
    color: #201EFF !important;
    border: 1px solid #201EFF;
    background-color: #ffffff !important;
    height: 50px;
    line-height: 32px;
  }
  .card-header {
    background-color: #F1F1F1;
  }
  .card-header button {
    font-size: 16px;
    color: #201EFF;
    font-weight: 400;
    border-width: 0px;
    background-color: #F1F1F1;
  }
  .card-header div {
    font-size: 16px;
    font-weight: 300;
  }
  .card-body, .form-label {
    font-size: 16px;
  }

`;
