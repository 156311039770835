import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Select from '../../components/Select';
import Button from '../../components/Button';

import logoImg from '../../assets/logo.svg';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

interface Type {
  id: string;
  name: string;
}

interface Client {
  id: string;
  name: string;
  image: string;
  city_id: string;
  occurrence: number;
  anonymous: number;
  anonymous_widget: number;
}

const Anonymous: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [types, setTypes] = useState<Type[]>([]);
  const [client, setClient] = useState<Client>();
  const [subTypes, setSubtypes] = useState<Type[]>([]);
  const [typeId, setTypeId] = useState('');
  const [inputType, setInputType] = useState(true);
  const [address, setAddress] = useState('');
  const [lat, setlat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [disabledAddress, setDisabledAddress] = useState(false);
  const [cityId, setCityId] = useState('');
  const [key, setKey] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  useEffect(() => {
    const storageKey = localStorage.getItem('@FalaCidadao:key');
    if (storageKey) {
      setKey(storageKey);
    }

    try {
      api
        .get<Client>(`/client/${storageKey}`, {
          params: {},
        })
        .then(response => {
          if (!response.data.anonymous_widget) {
            history.push('/');
          }

          setClient(response.data);
          setCityId(response.data.city_id);
          // setClientId(response.data.id);

          if (response.data.anonymous === 1) {
            formRef.current?.setData({ anonymous: '0' });
            // setInputAnonymous(false);
          }
          if (response.data.anonymous === 0) {
            formRef.current?.setData({ anonymous: '0' });
            // setInputAnonymous(true);
          }
        });
    } catch (err) {
      history.push('/');
    }
  }, [key, history]);

  useEffect(() => {
    if (cityId) {
      api
        .get<Type[]>(`/anonymous/types/${cityId}`, {
          params: {},
        })
        .then(response => {
          setTypes(response.data);
        });
    }
  }, [cityId]);

  interface OccurrenceFormData {
    client_id: string;
    type_id: string;
    city_id: string;
    description: string;
    address: string;
    latitude: string;
    longitude: string;
    public: number;
    anonymous: number;
    source: number;
  }

  useEffect(() => {
    if (typeId !== '') {
      api
        .get(`/anonymous/subtypes/${typeId}`, {
          params: {},
        })
        .then(response => {
          setSubtypes(response.data);
        });
    }
  }, [typeId]);

  const handleSubmit = useCallback(
    async (data: OccurrenceFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Descrição obrigatória'),
          type: Yup.string().required('Selecione a categoria'),
          type_id: Yup.string().required('Selecione o tipo de ocorrência'),
          // address: Yup.string().required('Informe o endereço da ocorrência'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // console.log(data);

        await api
          .post('anonymous/occurrences', {
            client_key: key,
            type_id: data.type_id,
            city_id: cityId,
            description: data.description,
            address: data.address,
            latitude: lat,
            longitude: lng,
            public: 0,
            anonymous: 0,
            source: 4,
          })
          .then(response => {
            const returnProtocol = response.data.protocol;
            if (returnProtocol) {
              if (containerRef.current) {
                window.scrollTo({
                  behavior: 'smooth',
                  top: containerRef.current.offsetTop,
                });
              }

              addToast({
                type: 'success',
                title: 'Enviado com sucesso',
                description: `Sua ocorrência foi enviada com sucesso, seu protocolo é: ${returnProtocol}`,
                time: 2000000,
              });
            }
          });

        formRef.current?.reset();
        setInputType(true);
        setDisabledAddress(false);
        setAddress('');
        setlat(0);
        setLng(0);

        /* addToast({
          type: 'success',
          title: 'Enviado com sucesso',
          description: 'Sua ocorrência foi enviado com sucesso.',
        }); */
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao enviar',
          description:
            'Ocorreu um erro ao enviar a ocorrência, verifique se preencheu todos os dados.',
        });
      }
    },
    [addToast, key, cityId, lat, lng],
  );

  return (
    <Container ref={containerRef}>
      <Row>
        <Col style={{ textAlign: 'center', margin: 40 }}>
          <img src={logoImg} alt="Fala Cidadão APP" />
        </Col>
      </Row>

      <Row>
        <Col style={{ textAlign: 'center' }}>
          <h1>Painel administrativo de ocorrências</h1>
          <p>
            Para acompanhar uma solicitação de ocorrência registrada você deve
            ser o solicitante da ocorrência.
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: 60, marginBottom: 80 }}>
        <Col sm={2} />
        <Col sm={8}>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Textarea
              name="description"
              placeholder="Descreva o que está acontecendo"
            />
            <Select
              onChange={e => {
                setTypeId(e.target.value);
                setInputType(false);
              }}
              name="type"
              label="Categoria"
              options={types}
            />
            <Select
              hidden={inputType}
              name="type_id"
              label="Tipo de ocorrência"
              options={subTypes}
            />
            <PlacesAutocomplete
              value={address}
              onChange={e => {
                setAddress(e);
              }}
              onSelect={e => {
                setAddress(e);

                geocodeByAddress(address)
                  .then(results => getLatLng(results[0]))
                  .then(latLng => {
                    setlat(latLng.lat);
                    setLng(latLng.lng);
                  });
                setDisabledAddress(true);
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <div
                    style={{
                      background: '#ffffff',
                      borderRadius: 5,
                      padding: 16,
                      width: '100%',
                      border: '1px solid #dbdbdb',
                      color: '#666666',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    hidden={!disabledAddress}
                  >
                    {address}
                  </div>

                  {disabledAddress === false && (
                    <div>
                      <Input
                        name="search_address"
                        placeholder="Endereço da ocorrência"
                        {...getInputProps({})}
                      />
                    </div>
                  )}

                  <div
                    style={{
                      visibility: 'hidden',
                      position: 'absolute',
                    }}
                  >
                    <Input
                      name="address"
                      placeholder="Endereço da ocorrência"
                      {...getInputProps({})}
                    />
                  </div>
                  <div>
                    {loading && <div>Carregando...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                          }
                        : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.description}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <Button type="submit">Enviar ocorrência</Button>
            {client && (
              <div
                style={{
                  marginTop: 20,
                  fontSize: 12,
                }}
              >
                <div>Você está enviando essa ocorrência para:</div>
                <div>
                  <b>{client.name}</b>
                </div>
              </div>
            )}
          </Form>
          <div style={{ marginTop: 30, textAlign: 'center' }}>
            <Link to="/">
              <FiArrowLeft />
              Voltar
            </Link>
          </div>
        </Col>
        <Col sm={2} />
      </Row>
    </Container>
  );
};

export default Anonymous;
